var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tingXiaH"},[_c('div',{staticClass:"top"},[_vm._m(0),_c('div',{staticClass:"top-c"},[_c('v-notice',{attrs:{"list":_vm.listData,"autoplay":"5000"},on:{"toEmit":_vm.toEmit}})],1),_vm._m(1)]),_c('div',{staticClass:"oTitle",on:{"click":function($event){return _vm.toMore(61)}}},[_c('div',{staticClass:"title-l"},[_vm._v("成效亮点")]),_vm._m(2)]),_c('div',{staticClass:"tingXiaH-main"},_vm._l((_vm.listData1),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.toDetail(item, index)}}},[_c('div',{staticClass:"item-l"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.topicTitle)+" ")]),_c('div',{staticClass:"item-lb"},[_c('div',{staticClass:"item-lbl"},[_vm._v(_vm._s(item.createTime))]),_c('div',{staticClass:"item-lbr"},[_vm._m(3,true),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.readCount))])])])]),_c('div',{staticClass:"item-r"},[_c('img',{attrs:{"src":item.topicPicture,"alt":""}})])])}),0),_c('div',{staticClass:"oTitle",on:{"click":function($event){return _vm.toMore(62)}}},[_c('div',{staticClass:"title-l"},[_vm._v("特色项目")]),_vm._m(4)]),_c('div',{staticClass:"tingXiaH-main"},_vm._l((_vm.listData2),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.toDetail(item, index)}}},[_c('div',{staticClass:"item-l"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.topicTitle)+" ")]),_c('div',{staticClass:"item-lb"},[_c('div',{staticClass:"item-lbl"},[_vm._v(_vm._s(item.createTime))]),_c('div',{staticClass:"item-lbr"},[_vm._m(5,true),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.readCount))])])])]),_c('div',{staticClass:"item-r"},[_c('img',{attrs:{"src":item.topicPicture,"alt":""}})])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-l"},[_c('img',{attrs:{"src":require("../img/laba.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-r"},[_c('img',{attrs:{"src":require("../img/arrow.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-r"},[_c('div',{staticClass:"txt"},[_vm._v("更多")]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../img/arrow.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../img/icon-read.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-r"},[_c('div',{staticClass:"txt"},[_vm._v("更多")]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../img/arrow.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../img/icon-read.png"),"alt":""}})])
}]

export { render, staticRenderFns }