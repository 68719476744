<template>
  <div class="tingXiaH">
    <div class="top">
      <div class="top-l"><img src="../img/laba.png" alt="" /></div>
      <div class="top-c">
        <v-notice :list="listData" autoplay="5000" @toEmit="toEmit"></v-notice>
      </div>
      <div class="top-r"><img src="../img/arrow.png" alt="" /></div>
    </div>
    <div class="oTitle" @click="toMore(61)">
      <div class="title-l">成效亮点</div>
      <div class="title-r">
        <div class="txt">更多</div>
        <div class="img"><img src="../img/arrow.png" alt="" /></div>
      </div>
    </div>
    <div class="tingXiaH-main">
      <div
        class="item"
        v-for="(item, index) in listData1"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="title">
            {{ item.topicTitle }}
          </div>
          <div class="item-lb">
            <div class="item-lbl">{{ item.createTime }}</div>
            <div class="item-lbr">
              <div class="img"><img src="../img/icon-read.png" alt="" /></div>
              <div class="txt">{{ item.readCount }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img :src="item.topicPicture" alt="" />
        </div>
      </div>
    </div>
    <div class="oTitle" @click="toMore(62)">
      <div class="title-l">特色项目</div>
      <div class="title-r">
        <div class="txt">更多</div>
        <div class="img"><img src="../img/arrow.png" alt="" /></div>
      </div>
    </div>
    <div class="tingXiaH-main">
      <div
        class="item"
        v-for="(item, index) in listData2"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="title">
            {{ item.topicTitle }}
          </div>
          <div class="item-lb">
            <div class="item-lbl">{{ item.createTime }}</div>
            <div class="item-lbr">
              <div class="img"><img src="../img/icon-read.png" alt="" /></div>
              <div class="txt">{{ item.readCount }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img :src="item.topicPicture" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "tingXiaH",
  data() {
    return {
      fromFlag: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      pointsData: {
        totlePointCount: 0,
      },
      curPage: 1,
      pageSize: 3,
      listData: [],
      bannerList: {},
      listData1: [],
      listData2: [],
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
  },
  mounted() {
    this.getPartyList();
    this.getPartyList1();
    this.getPartyList2();
  },
  methods: {
    toMore(data) {
      this.$router.push({
        name: "tingXiaHList",
        query: { type: data },
      });
    },
    toEmit(item) {
      this.$router.push({
        name: "tingXiaHReport",
        query: { id: item.id },
      });
    },
    toRedHousekeeper() {
      this.$router.push({
        name: "redUnion",
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "tingXiaHDetail",
        query: {
          id: item.id,
        },
      });
    },
    async getPartyList() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "60", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.curPage,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
    async getPartyList1() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "61", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.curPage,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData1 = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData1.push(item);
        });
      } else {
      }
    },
    async getPartyList2() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "62", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: this.curPage,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData2 = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData2.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaH {
  min-height: 100vh;
}
.top {
  padding: 14px 36px;
  background: rgba(251, 140, 72, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  .top-l {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .top-c {
    width: 588px;
    height: 60px;
    font-weight: 400;
    font-size: 28px;
    color: #fb8133;
    line-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .top-r {
    width: 28px;
    height: 28px;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.tingXiaH {
  .oTitle {
    padding: 0 34px 0 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .title-l {
      font-weight: 700;
      font-size: 28px;
      color: #323334;
      line-height: 40px;
      position: relative;
      padding-left: 40px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 8px;
        height: 26px;
        background: #fb8133;
        border-radius: 7px;
      }
    }
    .title-r {
      display: flex;
      align-items: center;
      .txt {
        font-weight: 500;
        font-size: 24px;
        color: #fb8133;
        line-height: 28px;
      }
      .img {
        width: 28px;
        height: 28px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .tingXiaH-main {
    .item {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        margin-right: 38px;
        .title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 44px;
          margin-bottom: 28px;
        }
        .item-lb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-lbl {
            font-size: 24px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 34px;
          }
          .item-lbr {
            display: flex;
            align-items: center;
            .img {
              width: 28px;
              height: 26px;
              margin-right: 4px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 24px;
              font-weight: 400;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
